import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VowelsComponent } from './vowels/vowels.component';
import { ConsonentsComponent } from './consonents/consonents.component';
import { GeminateConsonentsComponent } from './geminate-consonents/geminate-consonents.component';
import { ClusterComponent } from './cluster/cluster.component';


const routes: Routes = [
  {
    path: 'vowels', component: VowelsComponent,
    data: {preload: true},
  },
  {
    path: 'consonents', component: ConsonentsComponent,
    data: {preload: true},
  },
  {
    path: 'geminate-consonents', component: GeminateConsonentsComponent,
    data: {preload: true},
  },
  {
    path: 'cluster', component: ClusterComponent,
    data: {preload: true},
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AlphabetsRoutingModule { }
