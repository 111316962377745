import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignaturePadModule } from 'angular2-signaturepad';

import { AlphabetsRoutingModule } from './alphabets-routing.module';
import { VowelsComponent } from './vowels/vowels.component';
import { ConsonentsComponent } from './consonents/consonents.component';
import { GeminateConsonentsComponent } from './geminate-consonents/geminate-consonents.component';
import { ClusterComponent } from './cluster/cluster.component';

@NgModule({
  declarations: [VowelsComponent, ConsonentsComponent, GeminateConsonentsComponent, ClusterComponent],
  imports: [
    CommonModule,
    AlphabetsRoutingModule,
    SignaturePadModule,
  ]
})
export class AlphabetsModule { }
