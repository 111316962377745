import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import * as _ from 'lodash';

import { CONSONENTS_COUNT } from '../../shared/constants';

@Component({
  selector: 'app-consonents',
  templateUrl: './consonents.component.html',
  styleUrls: ['./consonents.component.css', '../../../assets/fonts/font-awesome/css/font-awesome.min.css']
})
export class ConsonentsComponent implements OnInit, AfterViewInit {

  @ViewChild(SignaturePad, { static: true }) public signaturePad: SignaturePad;

  public signaturePadOptions: Object = {
    'minWidth': 5,
    'canvasWidth': 600,
    'canvasHeight': 400
  };

  maxCount = CONSONENTS_COUNT;
  assetsAudioURL = 'assets/audio/';
  audioUrl = ['ka', 'kha', 'ga', 'gha', 'nga',
    'ca', 'cha', 'ja', 'jha', 'nja',
    'ta', 'tha', 'da', 'dha', 'nna',
    'tta', 'ttha', 'dda', 'dhha', 'na',
    'pa', 'pha', 'ba', 'bha', 'ma',
    'ya', 'ra', 'la', 'va', 'sa',
    'sha', 'ssa', 'ha', 'lla', 'zha', 'rra'
  ];
  selectedConsonent;
  indexOfSelected = 0;
  selectedAudioURL;
  consonents = ['ക', 'ഖ', 'ഗ', 'ഘ', 'ങ',
    'ച', 'ഛ', 'ജ', 'ഝ', 'ഞ',
    'ട', 'ഠ', 'ഡ', 'ഢ', 'ണ',
    'ത', 'ഥ', 'ദ', 'ധ', 'ന',
    'പ', 'ഫ', 'ബ', 'ഭ', 'മ',
    'യ', 'ര', 'ല', 'വ', 'ശ',
    'ഷ', 'സ', 'ഹ', 'ള', 'ഴ', 'റ'
  ];
  constructor() { }

  ngOnInit() {
    this.selectedConsonent = this.consonents[this.indexOfSelected];
    this.selectedAudioURL = this.audioUrl[this.indexOfSelected];
    console.log(this.selectedAudioURL);
  }

  ngAfterViewInit() {
    this.signaturePad.set('minWidth', 8);
    this.signaturePad.clear();
    this.signaturePad.set('penColor', 'rgb(15,62,133)');
    this.signaturePad.set('border', '2px solid black');
  }

  drawComplete() {
    console.log(this.signaturePad.toDataURL());
  }

  drawStart() {
    console.log('begin drawing');
  }

  clearCanvas() {
    this.signaturePad.clear();
  }

  submitDraw() {
    console.log('submit data:', this.signaturePad.toData());
  }

  goToPrevious() {
    this.indexOfSelected = _.indexOf(this.consonents, this.selectedConsonent);
    console.log(this.indexOfSelected);
    if (this.indexOfSelected > 0) {
      --this.indexOfSelected;
      this.selectedAudioURL = this.audioUrl[this.indexOfSelected];
      console.log(this.selectedAudioURL);
      this.selectedConsonent = this.consonents[this.indexOfSelected];
    }
  }

  goToNext() {
    this.indexOfSelected = _.indexOf(this.consonents, this.selectedConsonent);
    console.log(this.indexOfSelected);
    if (this.indexOfSelected < this.maxCount) {
      ++this.indexOfSelected;
      this.selectedAudioURL = this.audioUrl[this.indexOfSelected];
      console.log(this.selectedAudioURL);
      this.selectedConsonent = this.consonents[this.indexOfSelected];
    }
  }

}
