import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-geminate-consonents',
  templateUrl: './geminate-consonents.component.html',
  styleUrls: ['./geminate-consonents.component.css']
})
export class GeminateConsonentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
