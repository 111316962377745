import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AlphabetsModule } from './alphabets/alphabets.module';
import { WordsComponent } from './words/words.component';
import { SentenceComponent } from './sentence/sentence.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { PreDrawnComponent } from './pre-drawn/pre-drawn.component';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  {
    path: 'alphabets', loadChildren: () => import('./alphabets/alphabets.module').then(m => AlphabetsModule),
    data: { preload: true },
  },
  {
    path: 'words', component: WordsComponent,
    data: { preload: true },
  },
  {
    path: 'sentence', component: SentenceComponent,
    data: { preload: true },
  },
  {
    path: 'about', component: AboutComponent,
    data: { preload: true },
  },
  {
    path: 'contact', component: ContactComponent,
    data: { preload: true },
  },
  {
    path: 'pre-draw', component: PreDrawnComponent,
    data: { preload: true },
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
