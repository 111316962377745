import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import * as _ from 'lodash';

import { VOWELS_COUNT } from '../../shared/constants';

@Component({
  selector: 'app-vowels',
  templateUrl: './vowels.component.html',
  styleUrls: ['./vowels.component.css', '../../../assets/fonts/font-awesome/css/font-awesome.min.css']
})
export class VowelsComponent implements OnInit, AfterViewInit {

  @ViewChild(SignaturePad, { static: true }) public signaturePad: SignaturePad;

  public signaturePadOptions: Object = {
    'minWidth': 5,
    'canvasWidth': 600,
    'canvasHeight': 400
  };

  maxCount = VOWELS_COUNT;
  assetsAudioURL = 'assets/audio/';
  audioUrl = ['a', 'aa', 'i', 'ii', 'u', 'uu', 'ru', 'e', 'ee', 'ai', 'o', 'oo', 'au'];
  selectedVowel;
  indexOfSelected = 0;
  selectedAudioURL;
  vowels = ['അ', 'ആ', 'ഇ', 'ഈ', 'ഉ', 'ഊ', 'ഋ', 'എ', 'ഏ', 'ഐ', 'ഒ', 'ഓ', 'ഔ'];

  constructor() { }

  ngOnInit() {
    this.selectedVowel = this.vowels[this.indexOfSelected];
    this.selectedAudioURL = this.audioUrl[this.indexOfSelected];
    // console.log(this.selectedAudioURL);

  }

  ngAfterViewInit() {
    this.signaturePad.set('minWidth', 8);
    this.signaturePad.clear();
    this.signaturePad.set('penColor', 'rgb(15,62,133)');
    this.signaturePad.set('border', '2px solid black');
  }

  drawComplete() {
    console.log(this.signaturePad.toDataURL());
  }

  drawStart() {
    console.log('begin drawing');
  }

  clearCanvas() {
    this.signaturePad.clear();
  }

  submitDraw() {
    console.log('submit data:', this.signaturePad.toData());
  }

  goToPrevious() {
    this.indexOfSelected = _.indexOf(this.vowels, this.selectedVowel);
    console.log(this.indexOfSelected);
    if (this.indexOfSelected > 0) {
      --this.indexOfSelected;
      this.selectedAudioURL = this.audioUrl[this.indexOfSelected];
      console.log(this.selectedAudioURL);
      this.selectedVowel = this.vowels[this.indexOfSelected];
    }

    // this.selectedVowel = this.vowels[0];
  }

  goToNext() {
    this.indexOfSelected = _.indexOf(this.vowels, this.selectedVowel);
    // console.log(this.indexOfSelected);
    if (this.indexOfSelected < this.maxCount) {
      ++this.indexOfSelected;
      this.selectedAudioURL = this.audioUrl[this.indexOfSelected];
      // console.log(this.selectedAudioURL);
      this.selectedVowel = this.vowels[this.indexOfSelected];
    }
  }
}
